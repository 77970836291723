export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'title',
    label: 'Tiêu đề',
    minWidth: 120,
    type: 'text'
  }
]
